import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { PlpLogo } from "../../components/plp/PlpLogo";
import { ListingItem } from "../../components/storybook/ListingTreeMenu/types";
import { useNavigationPaths } from "../categories/categoryV3.helper";
import useMerchant from "./app/useMerchant";
import useScollections from "./useScollections";
import { useIsDepictLite } from "./useIsDepictLite";

function _useListingItems(merchantId?: string) {
  const { isDepictLite } = useIsDepictLite();
  const { scollections, isLoading, isError, refetch } = useScollections();
  const categoryNavigationPaths = useNavigationPaths();

  const location = useLocation();

  const listingItems: ListingItem[] = useMemo(() => {
    if (!merchantId) return [];
    const res: ListingItem[] = [];

    const scollectionsMap: Map<string, ListingItem> = new Map(
      scollections
        ?.filter((c) => {
          if (isDepictLite && c.collection_type === "smart_pick") return false;
          return true;
        })
        ?.map((collection) => {
          return [
            collection.collection_id,
            {
              id: collection.collection_id,
              title: collection.title,
              type: collection.collection_type,
              updatedAt: new Date(collection.updated_at),
              plpLogo: (isSelected) => (
                <PlpLogo
                  collectionType={collection.collection_type}
                  imageUrl={collection.image_urls?.[0]}
                  title={collection.title}
                  isSelected={isSelected}
                  typeSpecificId={collection.type_specific_id}
                />
              ),
              nProducts: () => {
                if (collection.n_main_products === undefined) return null;

                return collection.n_main_products;
              },
              children: [],
              portalNavigationPath:
                collection.collection_type === "look"
                  ? `/${merchantId}/looks/${collection.collection_id}`
                  : categoryNavigationPaths.getCategoryPath(
                      merchantId,
                      collection.collection_id
                    ),
            },
          ];
        })
    );

    scollections?.forEach((collection) => {
      const listingItem = scollectionsMap.get(collection.collection_id);
      if (!listingItem) return;
      if (collection.parent_id) {
        const parent = scollectionsMap.get(collection.parent_id);
        if (!parent) return;
        parent.children?.push(listingItem);
      } else {
        res.push(listingItem);
      }
    });

    return res;
  }, [merchantId, scollections, isDepictLite, categoryNavigationPaths]);

  const currentListingItemId = useMemo(() => {
    const findCurrentListingItemId = (
      listingItems: ListingItem[]
    ): string | undefined => {
      for (const listingItem of listingItems) {
        if (location.pathname === listingItem.portalNavigationPath) {
          return listingItem.id;
        }
        if (listingItem.children) {
          const res = findCurrentListingItemId(listingItem.children);
          if (res) return res;
        }
      }
      return;
    };
    return findCurrentListingItemId(listingItems);
  }, [listingItems, location.pathname]);

  return {
    scollections,
    listingItems,
    isLoading,
    isError,
    currentListingItemId,
    refetch,
  };
}

const useListingItems = () => {
  const { merchant } = useMerchant();

  const {
    listingItems,
    isLoading,
    currentListingItemId,
    isError,
    scollections,
    refetch,
  } = _useListingItems(merchant?.id);

  return {
    listingItems,
    isLoading,
    currentListingItemId,
    isError,
    scollections,
    refetch,
  };
};

export default useListingItems;

export function getNodeAndAncestorsWhere(
  where: (listingItem: ListingItem) => boolean,
  listingItems: ListingItem[]
) {
  const listingIds: Set<string> = new Set();
  const addListingIds = (
    listingItem: ListingItem,
    ancestorIds: string[] = []
  ) => {
    if (where(listingItem)) {
      listingIds.add(listingItem.id);
      ancestorIds.forEach((ancestorId) => listingIds.add(ancestorId));
    }
    if (listingItem.children) {
      listingItem.children.forEach((child) =>
        addListingIds(child, [...ancestorIds, listingItem.id])
      );
    }
  };
  listingItems.forEach((listingItem) => addListingIds(listingItem));
  return listingIds;
}

export function getNodeAndChildrenWhere(
  where: (listingItem: ListingItem) => boolean,
  listingItems: ListingItem[]
) {
  const listingIds: Set<string> = new Set();
  const addListingIds = (
    listingItem: ListingItem,
    condition: (listingItem: ListingItem) => boolean
  ) => {
    if (condition(listingItem)) {
      listingIds.add(listingItem.id);
      if (listingItem.children) {
        listingItem.children.forEach((child) =>
          addListingIds(child, () => true)
        );
      }
    }
    if (listingItem.children) {
      listingItem.children.forEach((child) => addListingIds(child, where));
    }
  };
  listingItems.forEach((listingItem) => addListingIds(listingItem, where));
  return listingIds;
}
