import { useMemo, Suspense, lazy } from "react";
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { PathContext, PathContextValue } from "src/contexts/PathContext";
import { useAuthorizedMerchantsContext } from "src/helpers/hooks/app/useAuthorizedMerchantsContext";
import SidenavLayout from "../layouts/SidenavLayout";
import {
  ROUTES,
  buildPathWithMerchantId,
  getPath,
  routeConfigs,
} from "./Routes";

import { SidebarContextProvider } from "../contexts/SidebarContext";
import SplashScreen from "../lite/components/SplashScreen/SplashScreen";

const NotFound = lazy(() => import("src/views/404"));
const Catalog = lazy(() => import("src/views/CatalogV2"));
const CategoryV3 = lazy(() => import("src/views/CategoryV3/CategoryV3"));
const NoMerchants = lazy(() => import("src/views/NoMerchants"));
const Settings = lazy(() => import("src/views/Settings/Settings"));
const AddAttribute = lazy(
  () => import("src/views/Settings/views/Attributes/AddAttribute")
);
const Attributes = lazy(
  () => import("src/views/Settings/views/Attributes/Attributes")
);
const EditAttribute = lazy(
  () => import("src/views/Settings/views/Attributes/EditAttribute")
);
const Users = lazy(() => import("src/views/Settings/views/Users/Users"));
const BoostAndBuryView = lazy(() => import("../views/BoostAndBury"));
const Search = lazy(() => import("../views/Search/Search"));
const TrackingDiagnosticsView = lazy(
  () => import("../views/TrackingDiagnostics/TrackingDiagnostics")
);
const DashboardV2 = lazy(() => import("src/views/Dashboard"));
const ErrorLayout = lazy(() => import("src/views/ErrorPage"));
const Admin = lazy(() => import("src/views/Settings/views/Admin/Admin"));
const EditFilter = lazy(
  () => import("src/views/Settings/views/Filters/EditFilter")
);
const Maintenance = lazy(() => import("../views/Maintenance"));
const AppShell = lazy(() => import("./AppShell"));
const LooksView = lazy(() => import("../views/Looks/Looks"));
const LookEditor = lazy(() => import("../views/LookEditor/LookEditor"));
const Recommendation = lazy(
  () => import("../views/RecommendationEditor/RecommendationEditor")
);
const Filters = lazy(() => import("../views/Settings/views/Filters/Filters"));
const AddFilter = lazy(
  () => import("../views/Settings/views/Filters/AddFilter")
);
const Recommendations = lazy(
  () => import("../views/Recommendations/Recommendations")
);
const OnboardingV2 = lazy(() => import("../views/Onboarding/OnboardingV2"));
const Onboarding = lazy(() => import("src/views/Onboarding"));
const AddUser = lazy(() => import("src/views/Settings/views/Users/AddUser"));
const GettingStartedView = lazy(
  () => import("../views/GettingStarted/GettingStarted")
);
const AppearanceSettings = lazy(
  () => import("../views/Settings/views/Appearance/AppearanceSettings")
);
const GridSliderSettings = lazy(
  () =>
    import("../views/Settings/views/Appearance/GridSlider/GridSliderSettings")
);
const ProductCardSettings = lazy(
  () =>
    import("../views/Settings/views/Appearance/ProductCard/ProductCardSettings")
);
const FilterSettings = lazy(
  () => import("../views/Settings/views/Appearance/Filter/FilterSettings")
);
const CentraAttributes = lazy(
  () => import("../views/Settings/views/CentraAttributes/CentraAttributes")
);

const DepictRouteElement = (route: ROUTES, element: JSX.Element) => {
  const pathContextValue = useMemo<PathContextValue>(() => {
    return {
      path: route,
      config: routeConfigs[route],
    };
  }, [route]);

  return (
    <PathContext.Provider value={pathContextValue}>
      <SidebarContextProvider>
        <SidenavLayout>{element}</SidenavLayout>
      </SidebarContextProvider>
    </PathContext.Provider>
  );
};

function NavigateToDashboard() {
  const fallbackMerchant =
    useAuthorizedMerchantsContext()?.authorizedMerchants?.[0];

  const path = buildPathWithMerchantId(
    ROUTES.DASHBOARD,
    fallbackMerchant?.id || ""
  );

  return <Navigate replace to={path} />;
}

export function MyRouter(baseName: string) {
  return createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          element={
            <Suspense fallback={<SplashScreen />}>
              <AppShell />
            </Suspense>
          }
          errorElement={
            <Suspense fallback={<SplashScreen />}>
              <ErrorLayout />
            </Suspense>
          }
        >
          <Route
            path={getPath(ROUTES.HOME)}
            element={<NavigateToDashboard />}
          />
          <Route
            path={getPath(ROUTES.LOGIN)}
            element={<NavigateToDashboard />}
          />
          <Route
            path={getPath(ROUTES.BOOST_AND_BURY)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(
                  ROUTES.BOOST_AND_BURY,
                  <BoostAndBuryView />
                )}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.DASHBOARD)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(ROUTES.DASHBOARD, <DashboardV2 />)}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.CATEGORY_V3)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(ROUTES.CATEGORY_V3, <CategoryV3 />)}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.CATALOG)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(ROUTES.CATALOG, <Catalog />)}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.SEARCH)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(ROUTES.SEARCH, <Search />)}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.RECOMMENDATIONS)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(
                  ROUTES.RECOMMENDATIONS,
                  <Recommendations />
                )}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.RECOMMENDATION)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(ROUTES.RECOMMENDATION, <Recommendation />)}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.LOOK)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(ROUTES.LOOK, <LookEditor />)}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.LOOKS)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(ROUTES.LOOKS, <LooksView />)}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.GETTING_STARTED_PAGE)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(
                  ROUTES.GETTING_STARTED_PAGE,
                  <GettingStartedView />
                )}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.ONBOARDING)}
            element={
              <Suspense fallback={<SplashScreen />}>
                <Onboarding />
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.GETTING_STARTED)}
            element={
              <Suspense fallback={<SplashScreen />}>
                <OnboardingV2 />
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.TRACKING)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(
                  ROUTES.TRACKING,
                  <TrackingDiagnosticsView />
                )}
              </Suspense>
            }
          />
          <Route
            path={getPath(ROUTES.SETTINGS)}
            element={
              <Suspense fallback={<SplashScreen />}>
                {DepictRouteElement(ROUTES.SETTINGS, <Settings />)}
              </Suspense>
            }
          >
            <Route
              path={getPath(ROUTES.SETTINGS_USERS)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <Users />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_USERS_ADD)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <AddUser />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_FILTERS)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <Filters />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_FILTERS_ADD)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <AddFilter />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_FILTERS_EDIT)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <EditFilter />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_ATTRIBUTES)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <Attributes />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_CENTRA_ATTRIBUTES)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <CentraAttributes />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_ATTRIBUTES_ADD)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <AddAttribute />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_ATTRIBUTES_EDIT)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <EditAttribute />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_APPEARANCE)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <AppearanceSettings />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_PRODUCT_CARD)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <ProductCardSettings />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_GRID_SLIDER)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <GridSliderSettings />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_FILTER)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <FilterSettings />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.SETTINGS_ADMIN)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <Admin />
                </Suspense>
              }
            />
          </Route>
          <Route
            element={
              <Suspense fallback={<SplashScreen />}>
                <ErrorLayout />
              </Suspense>
            }
          >
            <Route
              path={getPath(ROUTES.NO_MERCHANTS)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <NoMerchants />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.MAINTENANCE)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <Maintenance />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.NOT_FOUND)}
              element={
                <Suspense fallback={<SplashScreen />}>
                  <NotFound />
                </Suspense>
              }
            />
            <Route
              path={getPath(ROUTES.CATCH_ALL)}
              element={<Navigate replace to="/404" />}
            />
          </Route>
        </Route>
        <Route
          path={getPath(ROUTES.DASHBOARD_REDIRECT)}
          element={
            // Navigate app.depict.ai/edholm and app.depict.ai/edholm/ to app.depict.ai/edholm/dashboard
            <Navigate
              to={
                location.pathname +
                (location.pathname.endsWith("/") ? "" : "/") +
                "dashboard"
              }
              replace={true}
            />
          }
        />
      </>
    ),
    {
      basename: baseName,
    }
  );
}
