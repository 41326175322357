import { Interpolation, SerializedStyles, Theme, css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";
import { theme } from "../designSystemVariables";

export type TypographyColor = "base" | "subtle" | "inverse" | "info" | "accent";

interface Props extends PropsWithChildren {
  tagName?: keyof JSX.IntrinsicElements;
  baseStyles?: SerializedStyles;
  color?: TypographyColor;
  disabled?: boolean;
  clickable?: boolean;
  block?: boolean;
  styles?: Interpolation<Theme>;
}

export const Typography: FC<Props> = ({
  tagName = "span",
  baseStyles,
  color = "base",
  block,
  disabled,
  clickable,
  styles,
  children,
}) => {
  const Component = tagName;

  return (
    // @ts-expect-error
    <Component
      css={[
        css`
          ${baseStyles};
          color: ${theme.textIcon[color][disabled ? "disabled" : "default"]};
          ${block && `display: block;`}

          ${clickable &&
          `
          transition: color 0.2s;
          cursor: pointer;

          *:hover > & {
            color: ${theme.textIcon[color].hover};
          }

          *:active > & {
            color: ${theme.textIcon[color].pressed};
          }
        `}
        `,
        styles,
      ]}
    >
      {children}
    </Component>
  );
};
